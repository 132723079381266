.extended-hero-spot {
    &__legal {
        text-align: center;

        > ncg-legal .legal,
        &.legal-text-moved > ncg-legal .legal,
        &.legal-text-moved-desktop > ncg-legal .legal {
            color: $white;
        }
    }
}
