$black: black; // #000
$white: white; // #FFF

$black-bis: #2b2b2b;
$black-ter: #333;

$grey-darker: #444;
$grey-dark: #666;
$grey: #999;
$gray: #ccc;
$grey-light: whitesmoke; // #F5F5F5

$blue: #22a6f3;
$red: #e80200;

$primary: $red;
$text: $black;
$text-light: $grey-dark;

/* stylelint-disable */

// Colors from Figma
// https://www.figma.com/file/ZYjtLVTGlCLKL2E1RcIPeI/BYD-Group-Website-System-Design-V1?node-id=0%3A1

// Supporting colors
$f-1-900: #000; // Headline
$f-1-700: #4e5356; // Sub-headline
$f-1-500: #7a8080; // Background color
$f-1-300: #d4d8db; // Text color over dark bg
$f-1-100: #efefef; // Background

// Type
$f-2-900: #252728; // Primary
$f-2-700: #4e5356; // Secondary
$f-2-500: #62686b; // Tertiary
$f-2-300: #c7ccce; // Disabled
$f-2-100: #ffffff; // White

// Dividers & Borders
$f-b-main: #dcdddf; // Divider Light + Input Border
$f-b-dark: #888888; // Divider Dark
$f-b-hover: #252728; // Input Border Hover
$f-b-light: #ffffff; // Divider Light

// Overlays & BG
$f-overlay-light: #ffffff; // Overlay Light
$f-overlay-dark: rgba(122, 128, 128, 0.8); // Overlay Dark
$f-bg-white: #ffffff; // Background White
$f-bg-grey: #efefef; // Background Grey
$f-bg-grey-dark: #e7e7e7; // Background Grey Dark
$f-bg-dark: #252728; // Background Dark

/* stylelint-enable */
