.meta-menu {
    .navbar {
        .navbar-item,
        .navbar-link {
            font-size: 1.4rem;
            padding-left: 0.8rem;
            padding-right: 0.8rem;

            &.has--icon {
                gap: 0;
            }

            &--icon {
                margin-right: 0.5rem;
                width: 2rem;
                height: 2rem;
            }
        }
    }

    .navbar-item {
        @extend .is-hidden-touch;

        &__search {
            .navbar-item--text {
                display: none;
            }
        }
    }
}
