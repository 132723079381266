.image-text-picker-spot-swiper {
    @include until($desktop) {
        &__arrows {
            left: $section-padding-mobile-horizontal * -1;
            right: $section-padding-mobile-horizontal * -1;
        }

        &__prev {
            left: 0;
        }

        &__next {
            right: 0;
        }
    }
}
