@import '_variables/color-variables';

/****** COMMON DEFAULT VARIABLES *******/
@import '../../../../libs/common-styles/src/lib/styles/styles-variables';

/****** VARIABLES *******/
@import '_variables/variables';
@import '_variables/typography';

/****** COMMON LIB *******/
@import '../../../../libs/common-styles/src/lib/styles/styles';

/****** CORE *******/
@import 'core/swiper';

/****** SPOTS *******/
@import 'spots/spots';

/****** ELEMENTS *******/
@import 'elements/form';

/****** COMPONENTS *******/
@import 'components/navbar';

/****** FEATURES *******/
@import 'features/footer';
@import 'features/header';
@import 'features/meta-menu';
@import 'features/main-menu';
@import 'features/mobile-menu';
@import 'features/mega-models';
@import 'features/side-panel';
