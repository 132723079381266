.side-panel {
    &__close {
        &.button-icon.is-primary {
            // one of those cases where we need to override the default
            // transparent background button styling from BYD design system
            // as it does not cover this use case
            // - alternatively we could move the close button inside the side-panel?
            color: $button-primary-color;
        }
    }
}
