// TODO refactor to use the platform instead of this ...
.field {
    .input {
        border-width: $control-border-width;

        &.is-rounded {
            border-width: $control-border-width;
        }
    }

    .checkbox {
        .checkmark {
            border-width: $control-border-width;
            border-radius: 4px;

            &::after {
                // TODO use SVG in future
                border-width: 0 1.5px 1.5px 0;
                transform: rotate(45deg) skew(10deg, 10deg);
                border-color: $input-color;
            }
        }

        &:hover,
        &:focus {
            .checkmark {
                border-color: $input-hover-border-color;
            }
        }
    }

    .select select {
        border-width: $control-border-width;
    }
}
