$header-height: 6.4rem;
$header-bg-color: $black-bis;
$header-padding-horizontal: 4rem;

ncg-header {
    position: relative;
}

.header {
    position: relative;
    z-index: $index-header;
    background-color: $header-bg-color;
    box-shadow: inset 0 -1px 0 0 rgba($black, 40%);
    box-sizing: content-box;

    &__backdrop {
        &::before {
            content: '';
            position: fixed;
            background-color: $black;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: $index-body-overlay;
            pointer-events: none;
            opacity: 0;
            transition: opacity 300ms ease-in-out;
        }

        &--is-open {
            &::before {
                opacity: 0.3;
            }
        }
    }

    &--animate {
        transition: transform $image-animation-duration-fast $smooth-in;
    }

    &--is-sticky {
        position: fixed;
        top: 0;
        transition: transform $image-animation-duration-fast $smooth-out;
        width: 100%;
        box-shadow: none;
    }

    &__container {
        display: grid;
        grid-template-columns: 3rem 1fr auto 1fr 3rem;
        grid-template-rows: 1fr;
        grid-template-areas: '. logo main-menu meta-menu .';
        align-items: center;
        max-width: $fullwidth;
        height: $header-height-mobile;
        margin: 0 auto;
        gap: 0;

        @include desktop {
            height: $header-height;
        }

        @include widescreen {
            grid-template-columns: 4rem 1fr auto 1fr 4rem;
        }
    }

    &__logo {
        grid-area: logo;
        display: flex;
    }

    &__main-menu {
        @include desktop {
            grid-area: main-menu;
            align-self: stretch;
        }
    }

    &__meta-menu {
        @include until($desktop) {
            order: 1;
            align-items: flex-start;
            justify-content: flex-start;
            display: flex;

            a {
                color: $primary;
            }
        }

        @include desktop {
            grid-area: meta-menu;
        }
    }

    &__mobile-menu {
        grid-area: meta-menu;
        justify-self: end;

        .button {
            color: $white;
        }
    }
}

.logo {
    color: $white;
    display: inline-block;
    width: 80px;
    height: 16px;

    &:hover {
        color: $white;
    }

    @include desktop {
        width: 100px;
        height: 20px;
    }

    svg {
        width: 100%;
        height: auto;
    }
}
