$header-height: 6.4rem;

$search-button-close-color: $grey-darker;

// disable mega menu top divider
$mega-menu-border-top-space: 0;
$mega-menu-border-top-height: 0;
$mega-menu-border-top-background-color: transparent;

$model-page-header-nav-padding-bottom: 0;

$border: $f-b-main;
$hover: $primary;

$page-transition-bg: $grey-darker;
$page-transition-text: $grey-light;

$sub-nav-header-bg: $grey-darker;
$model-page-header-height: $header-height;
$model-page-header-inner-padding: 4rem;
$model-nav-active-box-width: -1px;

$form-input-height: 4.8rem;
$field-size-key: search;
$field-family-key: secondary;

$control-border-width: 1px;
$input-color: $f-2-900;
$input-background-color: $f-2-100;
$input-background-color-light: $f-2-100;
$input-background-color-dark: $f-2-100;
$input-padding-vertical: 1.2rem;
$input-background-color-danger: #faecea; // TODO read from color-variables
$input-border-color: $border;
$input-disabled-border-color: $border;
$custom-select-border-color: $border;
$dp-border-color: $border;
$dp-header-color: $input-color;
$input-hover-border-color: $f-b-hover;
$input-border-color-light: $input-border-color;
$input-border-color-dark: $input-border-color;
$checkmark-border-color: $input-border-color;
$checkmark-color: $input-border-color;
$input-focus-border-color: $input-border-color;
$input-focus-box-shadow-size: inset 0 -1px 0 0;
$input-focus-box-shadow-color: $input-border-color;

$rte-content-headline-margin-bottom: 3.2rem;

$mobile-links-icon-padding: 0;

$info-default-color: $f-2-500;
$info-light-color: $info-default-color;
$info-dark-color: $f-2-500;
$info-family-key: secondary;

// Dark background row
$dark-theme-bg: $f-bg-dark;
$dark-theme-text: $f-2-100;

// Button base
$button-size-key: search;
$button-family-key: secondary;
$button-font-weight: bold;

// Filled button in default row theme
$button-primary-bg: transparent;
$button-primary-color: $f-2-900;
$button-primary-border-color: currentColor;
$button-primary-hover-bg: $f-2-900;
$button-primary-text-hover-color: $f-2-100;
$button-primary-border-hover-color: $f-2-900;

// Filled button in light row theme
$button-filled-light-bg: $button-primary-bg;
$button-filled-light-text-color: $button-primary-color;
$button-filled-light-border-color: $button-primary-border-color;
$button-filled-light-bg-hover: $button-primary-hover-bg;
$button-filled-light-text-hover-color: $button-primary-text-hover-color;
$button-filled-light-border-hover-color: $button-primary-border-hover-color;

// Filled button in dark row theme
$button-filled-dark-bg: $button-primary-bg;
$button-filled-dark-text-color: $f-2-100;
$button-filled-dark-border-color: currentColor;
$button-filled-dark-bg-hover: $f-2-100;
$button-filled-dark-text-hover-color: $f-2-900;
$button-filled-dark-border-hover-color: $f-2-100;

// Outline button in default row theme
$button-outline-bg: $button-primary-bg;
$button-outline-text-color: $button-primary-color;
$button-outline-border-color: $button-primary-border-color;
$button-outline-bg-hover: $button-primary-hover-bg;
$button-outline-text-hover-color: $button-primary-text-hover-color;
$button-outline-border-hover-color: $button-primary-border-hover-color;

// Outline button in light row theme
$button-outline-light-bg: $button-outline-bg;
$button-outline-light-text-color: $button-outline-text-color;
$button-outline-light-border-color: $button-outline-border-color;
$button-outline-light-bg-hover: $button-outline-bg-hover;
$button-outline-light-text-hover-color: $button-outline-text-hover-color;
$button-outline-light-border-hover-color: $button-outline-border-hover-color;

// Outline button in dark row theme
$button-outline-dark-bg: $button-filled-dark-bg;
$button-outline-dark-text-color: $button-filled-dark-text-color;
$button-outline-dark-border-color: $button-filled-dark-border-color;
$button-outline-dark-bg-hover: $button-filled-dark-bg-hover;
$button-outline-dark-text-hover-color: $button-filled-dark-text-hover-color;
$button-outline-dark-border-hover-color: $button-filled-dark-border-hover-color;

// Hero button
$button-hero-color: $white;
$button-hero-bg: transparent;
$button-hero-border-color: currentColor;
$button-hero-hover-bg: $gray;
$button-hero-text-hover-color: $black;
$button-hero-border-hover-color: $gray;

// Headline spot
$default-subtitle-color: $black;
$light-subtitle-color: $default-subtitle-color;
$dark-subtitle-color: $white;

$headline-title-size-key-touch: larger;
$headline-title-desktop-size-key: larger;
$headline-title-font-weight: bold;
$headline-subtitle-touch-font-key: base;
$headline-subtitle-desktop-font-key: base;

$header-height-desktop: $header-height;
$header-height-mobile: 7.8rem;
$hero-title-font-weight: bold;
$mobile-chevron-back-icon-color: $black;
$mobile-chevron-icon-color: currentColor;
$mobile-close-icon-color: currentColor;
$mobile-header-spacing-bottom: 0;
$mobile-header-spacing-top: 0;
$mobile-nav-back-bg: $white;
$mobile-nav-back-border-bottom: transparent;
$mobile-nav-back-border-top: transparent;
$mobile-nav-back-color: currentColor;
$mobile-nav-back-size-key: x-small; // TODO figure out if 12px can be set at a wider level, maybe `navigationSmall`?
$mobile-nav-item-padding-bottom: 0;
$mobile-nav-item-padding-top: 0;
$model-nav-padding-vertical-desktop: 1.9rem;
$model-nav-padding-vertical-fullhd: 1.9rem;
$model-nav-padding-vertical: 0;
$model-page-header-border-color: rgba($white, 0.1);
$mobile-search-background: $grey-dark;
$mobile-search-border: $grey;
$mobile-search-color: $gray;
$mobile-search-icon-color: $gray;
$scrollbar-thumb-bg: #325680;
$sub-nav-cta-font-weight-key: medium;

$swiper-bullet-bg-active: $black;
$swiper-bullet-bg-active-dark: $white;
$swiper-bullet-bg: rgba($grey, 0.3);
$swiper-button-size-base: 6.4rem;
$swiper-button-size-small: 4.6rem;

$help-text-size: small;
$help-text-font-weight: bold;

$table-head-background-color: #f3f3f3;
$table-head-border-color: $border;

// Map spot
$map-phone-title-family-key: secondary;
$map-phone-title-size-key: base;

// CTA spot
$cta-link-hover-color: $hover;
$cta-link-hover-color--theme-dark: $hover;

// Minimal header and footer
$minimal-header-background-color: $black-bis;
$minimal-footer-background-color: $black-bis;

/****** CONFIGURATOR *******/
$configurator-tab-color: $grey-dark;
$configurator-tab-color-active: $black-bis;
$configurator-accent-color: $black-bis;
$configurator-header-height: 12rem;
$configurator-header-height-mobile: 6rem;

/****** CARD *******/
$card-active-color: $black-bis;
$card-border-color: $f-b-main;

/****** BREADCRUMB INDENTS *******/
$breadcrumb-indent-mobile: 20px;
$breadcrumb-indent-mobile-small: 20px;
$breadcrumb-indent-tablet: 20px;
$breadcrumb-indent-desktop: 5px;
$breadcrumb-indent-widescreen: 15px;
$breadcrumb-indent-fullhd: 15px;
$breadcrumb-indent-fullwidth: 15px;
