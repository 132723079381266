.swiper-button {
    &-prev,
    &-next {
        background-size: 1rem 2rem;
        background-position: center center;

        // TODO decide if we want to keep overriding `.is-small` here or make `.is-small` brand specific eg. using spotConfig
        &.is-small {
            @include desktop {
                --swiper-navigation-size: #{$swiper-button-size-base};
            }
        }
    }
}
