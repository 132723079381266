$footer-border: 1px solid rgba($black, 0.4);
$footer-navbar-item: $grey-dark;
$footer-navbar-item-hover: lighten($grey-dark, 20%);
$footer-navbar-item-spacing: 1rem;

ncg-footer {
    .footer {
        background-color: $black-bis;
        color: $grey;

        &-top {
            padding-top: 1rem;

            @include from($desktop) {
                padding-top: 4rem;
            }

            &__columns {
                @include until($desktop) {
                    padding-right: 2rem;
                    padding-left: 2rem;
                }

                @include from($desktop) {
                    justify-content: center;
                }
            }

            &__column {
                @include until($desktop) {
                    padding: 2rem 0;
                    border-bottom: $footer-border;
                }

                @include from($desktop) {
                    width: 20%;
                    flex: none;

                    .expansion-panel {
                        &__content {
                            height: auto !important;
                        }
                    }
                }
            }

            &__menu-label {
                flex: 1 0 auto;
                font-family: map-get($font-families, medium);

                @include from($desktop) {
                    padding-bottom: 1rem;
                }

                a {
                    color: $footer-navbar-item;
                    text-decoration: none;

                    @include from($desktop) {
                        color: $white;
                    }

                    &:hover,
                    &:focus {
                        color: $footer-navbar-item-hover;
                    }
                }
            }

            &__menu-list {
                a {
                    @include typeset(sub);

                    padding: 0.6rem 0;
                    color: $footer-navbar-item;
                    line-height: 1.2;
                    letter-spacing: map-get($letter-spacings, large);
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: $footer-navbar-item-hover;
                    }
                }
            }
        }

        &-bottom {
            @include from($desktop) {
                border-top: $footer-border;
            }

            > .container {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: repeat(4, auto);
                grid-template-areas:
                    'footer-logo'
                    'footer-social'
                    'footer-links'
                    'footer-copyright';
                grid-column-gap: $section-padding-mobile-horizontal;
                grid-row-gap: $section-padding-mobile-vertical;
                justify-items: center;

                @include from($desktop) {
                    grid-template-columns: repeat(2, auto);
                    grid-template-rows: repeat(2, auto);
                    grid-template-areas:
                        'footer-logo footer-social'
                        'footer-links footer-copyright';
                    grid-column-gap: 0;
                    grid-row-gap: 3rem;
                    justify-items: start;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            &__logo {
                grid-area: footer-logo;
            }

            &__social {
                grid-area: footer-social;
            }

            &__links {
                grid-area: footer-links;
            }

            &__copyright {
                grid-area: footer-copyright;

                @include from($desktop) {
                    justify-self: end;
                }
            }

            &__logo {
                color: $white;
            }

            &__social {
                .footer-bottom {
                    &__item {
                        color: currentColor;

                        &:hover,
                        &:focus {
                            color: $white;
                        }
                    }
                }
            }

            &__navbar {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-self: stretch;

                .navbar-item {
                    padding: $footer-navbar-item-spacing;
                }

                @include from($desktop) {
                    margin-right: $footer-navbar-item-spacing * -1;
                    margin-left: $footer-navbar-item-spacing * -1;
                }
            }
        }
    }
}
