$menu-hover-cover-height: 2.7rem;
$menu-hover-cover-top-horizontal: -0.7rem;
$menu-hover-cover-bottom-horizontal: -4rem;

.main-menu {
    &.navbar {
        position: static;
        height: 100%;

        .navbar-start {
            gap: 4rem;

            > div {
                display: flex;
                align-items: center;
            }
        }

        .main-menu {
            &__item {
                align-self: stretch;
                padding: 0 0.8rem;

                // TODO figure out why these pseudo elements are needed
                &:hover {
                    z-index: 2;

                    &::before,
                    &::after,
                    span::before,
                    span::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        width: 5rem;
                    }

                    &::before {
                        top: 50%;
                        left: $menu-hover-cover-top-horizontal;
                    }

                    &::after {
                        top: $menu-hover-cover-height;
                        left: $menu-hover-cover-bottom-horizontal;
                    }

                    span {
                        &::before {
                            top: 50%;
                            right: $menu-hover-cover-top-horizontal;
                        }

                        &::after {
                            top: $menu-hover-cover-height;
                            right: $menu-hover-cover-bottom-horizontal;
                        }
                    }
                }

                &.is-current {
                    font-weight: 600;
                    box-shadow: inset 0 -1px 0 0 $white;
                }
            }
        }
    }
}
