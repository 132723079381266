.mobile-menu {
    --item-bg-color: #{$white};
    --item-border-color: #{rgba($black, 0.12)};
    --item-color-active: #{$black};
    --item-color: #{$black};
    --item-font-size: 1.5rem;
    --item-font-weight: bold;
    --item-line-height: 1.5;
    --item-padding-x: 3.5rem;
    --item-padding-y: 3rem;

    &--first {
        --item-bg-color: #{$black-ter};
        --item-border-color: transparent;
        --item-color-active: #{$white};
        --item-color: #{$grey};
        --item-font-size: 2.8rem;
        --item-line-height: 2;
        --item-padding-x: 5rem;
        --item-padding-y: 0;
    }

    &__header {
        &.columns {
            align-items: center;
            height: $header-height-mobile;
        }
    }

    &__close {
        &--text {
            display: none;
        }
    }

    nav {
        background-color: var(--item-bg-color);
        color: var(--item-color);

        .mobile-menu {
            &__goto,
            &__item {
                padding-top: var(--item-padding-y);
                padding-right: var(--item-padding-x);
                padding-bottom: var(--item-padding-y);
                padding-left: var(--item-padding-x);
                background-color: var(--item-bg-color);
                border-color: var(--item-border-color);
                color: var(--item-color);
                font-size: var(--item-font-size);
                font-weight: var(--item-font-weight);
                line-height: var(--item-line-height);
            }

            &__goto {
                // TODO use centralized typography
                border-top-width: 1px;
                font-size: 1.5rem;
                line-height: 1.5;
                border-top-style: solid;
            }

            &__model {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            &__item {
                &.active {
                    color: var(--item-color-active);
                }

                &.back {
                    padding-top: 2.65rem;
                    padding-bottom: 2.65rem;
                    font-weight: $font-weight-bold;
                }
            }

            &__search {
                border-width: 1px;
            }
        }
    }
}

.mobile-model {
    &__image {
        width: 11.6rem;
        margin-left: 0;
    }

    &__title {
        // TODO use `typeset` mixin for consistency
        margin-bottom: 0;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.5;
    }

    &__text {
        color: $grey-dark;
        font-size: 1.5rem;
        font-weight: normal;
        line-height: 1.4;
    }
}
