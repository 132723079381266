$font-family-base: montserrat, sans-serif;
$font-family-base-medium: $font-family-base;
$font-family-base-bold: $font-family-base;
$font-family-secondary:
    Source Sans Pro,
    sans-serif;
$font-family-secondary-medium: $font-family-secondary;

$font-size-mobile-menu: 2.8rem;
$font-size-navigation: 1.6rem;

$font-size-x-small: 1rem; // micro
$font-size-small: 1.2rem; // small
$font-size-base: 1.5rem; // body
$font-size-medium: 2rem; // h4
$font-size-large: 2.4rem; // h3
$font-size-larger: 3.2rem; // h2
$font-size-largest: 4rem; // h1
$font-size-search: 1.6rem; // input

$line-height-normal: 1;
$line-height-tiny: 1.1666666666666667;
$line-height-small: 1.125;
$line-height-secondary: 1.2;
$line-height-base: 1.5;

$letter-spacing-base: 0;
$letter-spacing-x-small: 0;
$letter-spacing-small: 0;
$letter-spacing-medium: 0;
$letter-spacing-large: 0;
$letter-spacing-x-large: 0;
$letter-spacing-huge: 0;

// h1
$primary-heading-size-key: largest;
$primary-heading-line-key: secondary;
$primary-heading-weight-key: bold;

// h2
$secondary-heading-size-key: larger;
$secondary-heading-line-key: small;
$secondary-heading-weight: bold;

// h3
$tertiary-heading-size-key: large;
$tertiary-heading-line-key: small;
$tertiary-heading-weight: bold;

// h4
$small-heading-size-key: medium;
$small-heading-line-key: secondary;
$small-heading-family-key: bold;

/****** Function-specific vars ******/

// CONFIGURATOR
$configurator-headline-size: $font-size-large;
$configurator-headline-weight: 600;
$configurator-small-text-weight-bold: 600;
$configurator-label-font-size: $font-size-large;
